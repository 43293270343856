export const LEFT_MOUSE = 0;
export const RIGHT_MOUSE = 2;

export const TAB = 9;
export const ENTER = 13;
export const ESC = 27;
export const SPACE = 32;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const END = 35;
export const HOME = 36;
export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;

export const ZERO = 48;
export const NINE = 57;
export const KEYPAD_ZERO = 96;
export const KEYPAD_NINE = 105;
